<template>
  <div class="login_container">
    <div class="logo">
      <img src="../../assets/login_logo.png" alt="" />
    </div>
    <div class="bg1">
      <img src="../../assets/login_bg1.png" alt="" />
    </div>
    <div class="bg2">
      <img src="../../assets/login_bg2.png" alt="" />
    </div>
    <div class="login_box">
      <div class="form_logo">
        <img src="../../assets/form_logo.png" alt="" />
      </div>
      <div class="form_item">
        <div class="icon">
          <img src="../../assets/username.png" alt="" />
        </div>
        <input
          type="text"
          v-model.trim="user_name"
          maxlength="24"
          @input="ipt"
          placeholder="请输入用户名"
        />
      </div>
      <div class="form_item pwd">
        <div class="icon">
          <img src="../../assets/pwd.png" alt="" />
        </div>
        <input
          :type="showPwd ? 'text' : 'password'"
          v-model.trim="pwd"
          maxlength="16"
          @keyup.enter="checkLogin"
          placeholder="请输入密码"
        />
        <div class="eye">
          <img @click="showPwd = !showPwd" src="../../assets/eye.png" alt="" />
        </div>
      </div>

      <div class="login_btn cursor" @click="checkLogin">登录</div>
    </div>
  </div>
</template>

<script>
import { LoginApi } from '@/api'
import MD5 from 'md5'

export default {
  data() {
    return {
      // 用户名 0-24 位
      user_name: '',
      // 密码 6-16位
      pwd: '',
      // 是否显示密码
      showPwd: false
    }
  },
  methods: {
    // 校验登录
    checkLogin() {
      // this.$router.replace('/welcome')
      const user_name = this.user_name
      const pwd = this.pwd
      if (!user_name) {
        return this.$message.warning('请输入用户名')
      }
      if (/[\u4e00-\u9fa5]/.test(user_name)) {
        return this.$message.warning('用户名不能包含文字')
      }
      if (!pwd) {
        return this.$message.warning('请输入密码')
      }
      if (pwd.length < 6) {
        return this.$message.warning('密码长度 6 ~ 16 位')
      }
      this.login()
    },

    // 输入用户名, 替换文字
    ipt() {
      this.user_name = this.user_name.replace(/[\u4e00-\u9fa5]/g, '')
    },

    async login() {
      const params = { user_name: this.user_name, pwd: MD5(this.pwd) }
      const { ret, data, msg } = await LoginApi(params)
      if (ret === 8) {
        this.$message.error(msg)
        setTimeout(() => {
          window.location.reload()
        }, 500)
        return
      }
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('登录成功')
      this.$store.dispatch('user/saveToken', data).then(() => {
        this.$router.replace('/welcome').catch(() => {})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes move {
  0% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-30px);
  }
}

.login_container {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(-56deg, #05101c, #0b1828);

  .logo {
    position: absolute;
    top: 63px;
    left: 93px;
    z-index: 3;
    width: 123px;
    height: 29px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bg1 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;

    img {
      height: 100%;
      width: 100vw;
    }
  }

  .bg2 {
    position: absolute;
    z-index: 4;
    top: 9%;
    left: 5.6%;
    width: 861px;
    height: 750px;

    img {
      width: 861px;
      height: 750px;
      animation: move 4s infinite;
    }
  }

  .login_box {
    position: fixed;
    right: 13.5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 6;
    height: 371px;
    width: 364px;

    .form_logo {
      width: 179px;
      height: 58px;
      margin-left: 74px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .form_item {
      width: 364px;
      height: 56px;
      border: 1px solid #6f7d95;
      border-radius: 4px;
      margin: 59px auto 0;
      display: flex;
      background-color: #fff;

      .icon {
        width: 56px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #6f7d95;

        img {
          width: 16px;
          height: 16px;
        }
      }

      input {
        flex: 1;
        background-color: #fff;
        border: none;
        outline: none;
        font-size: 18px;
        font-family: Alibaba;
        font-weight: normal;
        color: #333;
        padding-left: 20px;

        &::placeholder {
          font-size: 16px;
          font-family: Alibaba;
          font-weight: normal;
          color: #77859c;
        }
      }

      .eye {
        width: 56px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #6f7d95;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .pwd {
      margin-top: 37px;
    }

    .login_btn {
      width: 364px;
      height: 54px;
      background: #0062ff;
      border-radius: 12px;
      margin-top: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      transition: all 0.2s;
      user-select: none;

      &:hover {
        background-color: rgba(10, 93, 221, 0.96);
      }
    }
  }
}
</style>
